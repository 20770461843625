<template>
  <div class="features">
    <div class="features_box">
      <div class="features_top">
        <p>营销功能</p>
        <p>MARKETING SYSTEM</p>
      </div>
      <div class="features_button">
        <div class="content">
          <p>加入会员</p>
          <p>活动推送</p>
          <p>门店核销</p>
          <p>小程序消息推送</p>
          <p>卡券到期提醒</p>
          <p>生活号图文<br />推送</p>
          <p>领取积分</p>
          <p>线上<br />下单</p>
          <p>
            线下门店<br />
            +<br />
            客户沉淀
          </p>
          <p>
            线上反馈<br />
            +<br />
            引流至线下
          </p>
          <p>
            线上触达<br />
            +<br />
            复购
          </p>
          <p>会员积分兑<br />换</p>
          <p>
            线上成交<br />
            +<br />
            活跃
          </p>
          <p>参与<br />红包抽奖</p>
          <p>扫码领券</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [],
    };
  },
};
</script>
<style scoped lang="less">
.features {
  width: 100%;
  overflow: hidden;
  background: #F7F7F7;
  .features_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 180px;
    .features_top {
      text-align: center;
      margin-bottom: 80px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
      }
    }
    .features_button {
      height: 700px;
      background: url("../../../../assets/imgs/value/bnkl.png") center no-repeat;
      margin-bottom: 170px;
      .content {
        max-width: 890px;
        height: 700px;
        margin: 0 auto;
        position: relative;
        p:nth-child(1) {
          font-size: 8px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          position: absolute;
          left: 152px;
          top: 87px;
        }
        p:nth-child(2) {
          font-size: 8px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          position: absolute;
          left: 84px;
          top: 279px;
        }
        p:nth-child(3) {
          font-size: 8px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          position: absolute;
          left: 120px;
          bottom: 158px;
        }
        p:nth-child(4) {
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          position: absolute;
          right: 125px;
          bottom: 79px;
        }
        p:nth-child(5) {
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          position: absolute;
          right: 37px;
          bottom: 288px;
        }
        p:nth-child(6) {
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          text-align: center;
          line-height: 30px;
          position: absolute;
          top: 180px;
          right: 45px;
        }
        p:nth-child(7) {
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          position: absolute;
          top: 68px;
          right: 140px;
        }
        p:nth-child(8) {
          font-size: 13px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #666666;
          line-height: 30px;
          position: absolute;
          bottom: 280px;
          left: 190px;
        }
        p:nth-child(9) {
          font-size: 17px;
          font-family: PingFangSC;
          font-weight: 600;
          color: #333333;
          text-align: center;
          line-height: 25px;
          // border-radius: 50%;
          // width: 125px;
          // height: 100px;
          // background: white;
          // padding-top: 25px;
          position: absolute;
          top: 200px;
          left: 182px;
        }
        p:nth-child(10) {
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 600;
          color: #333333;
          text-align: center;
          line-height: 25px;
          position: absolute;
          bottom: 83px;
          left: 260px;
        }
        p:nth-child(11) {
          font-size: 17px;
          font-family: PingFangSC;
          font-weight: 600;
          color: #333333;
          text-align: center;
          line-height: 25px;
          position: absolute;
          bottom: 160px;
          right: 184px;
        }
        p:nth-child(12) {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          text-align: center;
          line-height: 25px;
          color: #666666;
          position: absolute;
          top: 314px;
          right: 174px;
        }
        p:nth-child(13) {
          font-size: 17px;
          font-family: PingFangSC;
          font-weight: 600;
          color: #333333;
          text-align: center;
          line-height: 25px;
          position: absolute;
          top: 134px;
          right: 200px;
        }
        p:nth-child(14) {
          font-size: 15px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          text-align: center;
          line-height: 25px;
          color: #666666;
          position: absolute;
          top: 175px;
          left: 370px;
        }
        p:nth-child(15) {
          font-size:35px;
          font-family: Adobe Heiti Std;
          letter-spacing: 2px;
          color: #333333;
          position: absolute;
          top: 310px;
          left: 370px;
        }
      }
    }
  }
}
</style>

