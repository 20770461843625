


<template>
  <!-- baner -->
  <div class="Baner">
    <div class="Baner">
      <a class="Carousel_img"
        ><img src="@/assets/imgs/value/baenr.png" alt=""
      /></a>
      <div class="position">
        <div
          class="slit"
          v-for="(item, index) in content"
          :key="index"
          v-show="active == index"
        >
          <div v-for="(itel, index) in item.cont" :key="index">
            <p>{{ itel.text }}</p>
            <p>{{ itel.texti }}</p>
            <p>{{ itel.texta }}</p>
            <button>立即体验</button>
          </div>
        </div>
        <div class="iasm">
          <div class="inedx"  v-for="(item, index) in content"
          :key="index"
           @mouseover="active = index"
          >
            <p>{{item.nav}}</p>
            <i class="i"  v-show="active == index"></i>
            
          </div>
                   
        </div>
         <div class="bacound"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          nav: "定制服务",
          cont: [
            {
              text: "品牌扶持",
              texti: "CUSTOMIZED SERVICES",
              texta:
                " 梳理用户需求，结合用户真实情况和当下市场情况,快速找准用户痛点，流程清晰明了，项目实时跟进，为用户进行量身打造合适的方案",
            },
          ],
        },
        {
          nav: "广告服务",
          cont: [
            {
              text: "2品牌扶持",
              texti: "CUSTOMIZED SERVICES",
              texta:
                " 梳理用户需求，结合用户真实情况和当下市场情况,快速找准用户痛点，流程清晰明了，项目实时跟进，为用户进行量身打造合适的方案",
            },
          ],
        },
        {
          nav: "平台运营",
          cont: [
            {
              text: "3品牌扶持",
              texti: "CUSTOMIZED SERVICES",
              texta:
                " 梳理用户需求，结合用户真实情况和当下市场情况,快速找准用户痛点，流程清晰明了，项目实时跟进，为用户进行量身打造合适的方案",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.Baner,
.Carousel_img,
.Carousel_img > img {
  min-width: 1225px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
 min-height: 755px;
}

.Carousel_img {
  display: inline-block;
  position: relative;
}

.position {
  position: absolute;
  top: 260px;
  left: 230px;
  width: 1155px;
  height: 500px;
  .slit {
    margin: 0 auto;
    width: 660px;
    float: left;
    height: 300px;
    p:nth-child(1) {
      font-size: 70px;
      font-family: Source Han Sans CN;
      letter-spacing: 2px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 25px;
    }
    p:nth-child(2) {
      font-size: 19px;
      font-family: Source Han Sans CN;
      letter-spacing: 4px;
      font-weight: 400;
      color: #ffffff;
    }
    p:nth-child(3) {
      width: 630px;
      margin-top: 80px;
      letter-spacing: 1px;
      font-size: 23px;
      line-height: 29px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    button {
      width: 136px;
      height: 35px;
      border: 1px solid #fafafa;
      border-radius: 10px;
      font-size: 18px;
      font-family: FZHei-B01S;
      font-weight: 400;
      margin-top: 75px;
      color: #fafafa;
      outline: none;
      letter-spacing: 2px;
    }
  }
  .iasm {
    float: right;
    width: 395px;
    height: 100px;
    margin-top: 399px;
    .inedx {
      text-align: center;
      .i {
        display: inline-block;
        width: 80px;
        height: 2px;
        background: #224ede;
        position: relative;
        z-index: 100;
      }
      p {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 7px;
        cursor: pointer;
      }
    }
    .inedx:nth-child(1) {
      float: left;
    }
    .inedx:nth-child(2) {
      float: right;
    }

  }
      .bacound {
      width: 395px;
      height: 2px;
      background: #ffffff;
      position: relative;
     
      top: 432px;
      left: 760px;
    }
}
</style>

