<template>
  <div>
    <Baner />
    <Features />
    <Core />
    <Channel />
  </div>
</template>

<script>
import Baner from "@/components/pagespers/program/added/Baner.vue";
import Features from "@/components/pagespers/program/added/Features.vue";
import Core from "@/components/pagespers/program/added/Core.vue";
import Channel from "@/components/pagespers/program/added/Channel.vue";

export default {
  components: {
    Baner,
    Features,
    Core,
    Channel,
  },

  data() {
    return {};
  },
};
</script>

<style scoped lang="less"></style>
