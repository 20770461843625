<template>
  <div class="core">
    <div class="core_box">
      <div class="core_top">
        <p>核心优势</p>
        <p>ADVANTAGE</p>
      </div>
      <div class="core_bottom">
        <div
          class="content_inedx"
          v-for="(list, inedx) in content"
          :key="inedx"
        >
          <img :src="list.img" alt="" />
          <p>{{ list.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/value/hx2.png"),
          text: "精准定位，多样化的广告资源",
        },
        {
          img: require("@/assets/imgs/value/hx.png"),
          text: "提供多种落地模版，提供多方面指导和代运营",
        },
        {
          img: require("@/assets/imgs/value/hx1.png"),
          text: "基础用户定向，基于用户分析进行相似人群拓展",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.core {
  width: 100%;
  overflow: hidden;
  background: #F7F7F7;
  .core_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 180px;
    .core_top {
      text-align: center;
      margin-bottom: 80px;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: bold;
        font-family: Adobe Heiti Std;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
      }
    }
    .core_bottom {
      height: 400px;
      .content_inedx {
        text-align: center;
        margin: 0 auto;
        width: 245px;
        p {
          font-size: 18px;
          font-family: unset;
          font-weight: 600;
          color: #333333;
          line-height: 25px;
          text-align: center;
          margin-top: 50px;
        }
      }
      .content_inedx:nth-child(1) {
        float: left;
      }
      .content_inedx:nth-child(2) {
        float: right;
      }
    }
  }
}
</style>

