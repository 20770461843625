
<template>
  <div class="channel">
    <div class="channel_box">
      <div class="channel_top">
        <p>多样化渠道</p>
        <p>DIVERSIFICATION</p>
      </div>
      <div class="channel_bottom">
        <div class="content_inedx" v-for="(ilst,inedx) in content" :key="inedx">
          <img :src="ilst.img" alt="" />
          <div class="position">
            <p>{{ilst.text}}</p>
            <p>{{ilst.texti}}</p>
          </div>
        </div>
    
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/value/qd.png"),
          text: "门店引流",
          texti:"引导用户至门店消费,针对不同的消费群体，进行相对应的用户疏导"
        },
        {
          img: require("@/assets/imgs/value/qd1.png"),
              text: "线下体验",
          texti: "对到店客户，针对不同的消费人群，提供不同的线下产品以及使用体验",
        },
        {
          img: require("@/assets/imgs/value/qd2.png"),
              text: "多方位分销",
          texti: "发动粉丝以及员工进行分销，建立全面的分销网络和多方位推广渠道",
        },
        {
          img: require("@/assets/imgs/value/qd3.png"),
              text: "会员经营",
          texti: "会员群体将会建立其对应的专属利益，保障会员的权益，产生更多效益",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.channel {
  width: 100%;
  overflow: hidden;
  background: #F7F7F7;
  .channel_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 70px;
    .channel_top {
      text-align: center;
      margin-bottom: 80px;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: bold;
        font-family: Adobe Heiti Std;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
      }
    }
    .channel_bottom {
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      height: 500px;
      .content_inedx {
        overflow: hidden;
        border-radius: 20px;
        position: relative;
        img {
          width: 270px;
        }
        .position {
          width: 270px;
          height: 307px;
          border-radius: 20px;
          position: absolute;
          top: 0px;
          left: 0px;
          //background: rgba(0, 0, 0, 0.2);
          text-align: center;
          p:nth-child(1) {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            letter-spacing: 1px;
            margin-top: 100px;
          }
          p:nth-child(2) {
            width: 177px;
            margin: 0 auto;
            margin-top: 30px;
            text-align: left;
            font-size: 15px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 2px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>

